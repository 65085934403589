<div class="chat-icon bg-red-600 hover:bg-red-500" (click)="toggleChat()">
  <fa-icon [icon]="faCommentDots" class="text-4xl text-white"></fa-icon>
</div>

@if (showChat) {
  <div class="chat-window bg-white shadow-lg rounded-2xl text-xl">
    <div class="bg-red-600 text-white p-2 rounded-t-2xl text-center">
      <div class="font-bold text-3xl py-2">Contact us</div>
      <div>Leave us a message.</div>
      <div>We will respond soon!</div>
    </div>
    <div class="chat-body p-4">
      <form [formGroup]="formGroup" (submit)="sendMessage()">
        <app-text-input
          [control]="formGroup.get('name')!"
          placeholder="Name"
        ></app-text-input>
        <app-text-input
          [control]="formGroup.get('email')!"
          placeholder="Email"
        ></app-text-input>
        <app-text-input
          [control]="formGroup.get('message')!"
          placeholder="Message"
          [isTextArea]="true"
        ></app-text-input>
        <div>
          <select
            formControlName="category"
            required
            class="p-2 bg-white rounded w-full"
            [ngClass]="
              formGroup.get('category')?.value ? '!border-green-600' : ''
            "
          >
            <option value="" disabled [selected]="true">Category:</option>
            <option>Payment</option>
            <option>Movie creation</option>
            <option>Other</option>
          </select>
        </div>
        <button
          type="submit"
          class="text-white font-bold py-2 px-4 rounded my-2"
          [ngClass]="
            formGroup.valid ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-400'
          "
          [disabled]="!formGroup.valid"
        >
          Send
        </button>
      </form>
    </div>
  </div>

  <div class="chat-icon bg-red-600 hover:bg-red-500" (click)="toggleChat()">
    <fa-icon [icon]="faXMark" class="text-4xl text-white"></fa-icon>
  </div>
}
