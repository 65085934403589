import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { BasketItemsService } from './basketItems.service';

@Injectable({
  providedIn: 'root',
})
export class EmptyBasketGuard implements CanActivate {
  constructor(
    private readonly basketItemsService: BasketItemsService,
    private readonly notificationService: NotificationService,
  ) {}

  canActivate() {
    const isBasketEmpty = this.basketItemsService.isBasketEmpty();
    if (isBasketEmpty) {
      this.notificationService.showError('Your basket is empty');
    }

    return !isBasketEmpty;
  }
}
