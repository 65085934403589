import { Component } from '@angular/core';
import { Notification, NotificationService, NotificationType } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  animations: [
    trigger("showNotification", [
      transition(":enter", [
        style({ transform: "translateY(-200%)" }),
        animate(
          "200ms cubic-bezier(.46,.33,.5,1.68)",
          style({ transform: "translateY(0%)" })
        ),
      ]),
      transition(":leave", [
        animate(
          "200ms cubic-bezier(.38,-0.59,.72,.88)",
          style({ transform: "translateY(-200%)" })
        ),
      ]),
    ]),
  ],
})
export class NotificationComponent {
  notifications: Notification[] = [];
  private subscription: Subscription;

  constructor(private notificationService: NotificationService) { }

  private addNotification(notification: Notification) {

    var duplicates = this.notifications.filter(x => x.message == notification.message);

    if (duplicates.length > 0) {
      duplicates[0].timeout = notification.timeout;
      setTimeout(() => this.close(duplicates[0]), duplicates[0].timeout);
    }
    else {
      this.notifications.push(notification);
      if (notification.timeout !== 0) {
        setTimeout(() => this.close(notification), notification.timeout);
      }
    }
  }

  ngOnInit() {
    this.subscription = this.notificationService
      .getObservable()
      .subscribe((notification) => this.addNotification(notification));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(
      (x) => x.id !== notification.id
    );
  }

  className(notification: Notification): string {
    let style: string;

    switch (notification.type) {
      case NotificationType.success:
        style = "bg-green-600";
        break;

      case NotificationType.error:
        style = "bg-red-600";
        break;
    }

    return style;
  }
}
