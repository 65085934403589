<div class="footer-section">
  <div class="title">{{ footerSection.title }}</div>
  @if (footerSection.links.length != 0) {
    <div class="flex flex-col gap-2">
      @for (link of footerSection.links; track $index) {
        <a [href]="link.url" class="link">{{ link.label }}</a>
      }
    </div>
  }
  @if (footerSection.newsletterSignup) {
    <div class="your-email-input-wrapper">
      <div class="your-email-input">
        <input
          [placeholder]="footerSection.newsletterSignup.placeholder"
          class="p-2 w-full"
        />
        <button
          class="bg-black text-white p-2 w-1/2"
          (click)="signupToNewsletter()"
        >
          {{ footerSection.newsletterSignup.label }}
        </button>
      </div>
      <div>{{ footerSection.newsletterSignup.dataPolicyLabel }}</div>
    </div>
  }
  @if (footerSection.imagesWithLinks.length != 0) {
    <div class="flex flex-row gap-4">
      @for (imageWithLink of footerSection.imagesWithLinks; track $index) {
        <a [href]="imageWithLink.url">
          <img appImageUrl [iconData]="imageWithLink.image.data" class="h-12" />
        </a>
      }
    </div>
  }
  @if (footerSection.imagesWithFancyboxes.length != 0) {
    <div class="flex flex-row gap-4">
      @for (
        imageWithFancybox of footerSection.imagesWithFancyboxes;
        track $index
      ) {
        <img
          appImageUrl
          [iconData]="imageWithFancybox.image.data"
          class="max-h-12 max-w-24 cursor-pointer"
          (click)="openModal(imageWithFancybox.fancybox.data.attributes)"
        />
      }
    </div>
  }
</div>
