<div class="absolute inset-0 z-50">
  <div class="fixed backdrop-brightness-50 h-full w-full flex place-items-center place-content-center text-lg"
    (click)="closePopup()">
    <div class="flex flex-col bg-white w-96 rounded-lg" (click)="$event.stopPropagation()">
      <div class="bg-red-600 text-white text-3xl font-rockwell py-4 px-12 text-center rounded-t-lg">
        Log in
      </div>
      <div class="p-4">
        <div>Your data</div>

        <form [formGroup]="loginForm" (submit)="login()">
          <app-text-input [control]="loginForm.get('email')!" placeholder="Email"></app-text-input>
          <app-text-input [control]="loginForm.get('password')!" placeholder="Password"
            type="password"></app-text-input>
          @if (authFailed) {
          <div class="text-center text-red-600">
            Wrong e-mail or password.
          </div>
          }
          <div class="mt-6 w-4/5 mx-auto">
            <button class="text-white font-bold py-3 text-xl rounded w-full" [ngClass]="
                loginForm.valid ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-400'
              " [disabled]="!loginForm.valid" type="submit">
              Log in
            </button>
          </div>
        </form>
        <div class="mt-8 flex flex-col items-center">
          <button (click)="redirectToForgottenPassword()" class="hover:text-red-500">
            Forgotten password?
          </button>
          <button (click)="redirectToRegister()" class="hover:text-red-500">
            Don't have an account yet? Register
          </button>
        </div>
      </div>
    </div>
  </div>
</div>