<div
  class="min-w-[20%] sm:max-w-[50%] md:max-w-[40%] lg:max-w-[20%] fixed right-7 top-20 z-50"
>
  @for (notification of notifications; track $index) {
    <div
      class="m-2 rounded break-words text-white"
      [ngClass]="className(notification)"
      [@showNotification]="true"
    >
      <div (click)="close(notification)" class="p-3">
        {{ notification.message }}
      </div>
    </div>
  }
</div>
