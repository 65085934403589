import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { CommonModule } from "@angular/common";
import { Component, HostListener, OnInit, signal } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faBars,
  faCartShopping,
  faPowerOff,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { map, Observable, Subject, takeUntil } from "rxjs";
import { AppConfigService } from "../../config.service";
import {
  BASKET_MODAL_REF,
  BasketPopupComponent
} from "../../containers/basket/basket-popup/basket-popup.component";
import { Navbar } from "../../models/singleTypes/navbar.model";
import { ModalService } from "../../services/modal.service";
import { NavbarService } from "../../services/strapi/navbar.service";
import { RouteUrls } from "../../shared/enums/route-urls.enum";
import { ButtonComponent } from "../button/button.component";
import { BasketItemsService } from "../../containers/basket/services/basketItems.service";
import { LoginPopupComponent, LOGIN_MODAL_REF } from "../authentication/login-popup/login-popup.component";
import { AuthService, User } from "../../services/auth/auth.service";

@Component({
  standalone: true,
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    FontAwesomeModule,
    BasketPopupComponent,
  ],
  animations: [
    trigger("openCloseMobileMenu", [
      state(
        "open",
        style({
          height: "110vh",
          visibility: 'visible'
        })
      ),
      state(
        "closed",
        style({
          height: "0vh",
          visibility: 'hidden'
        })
      ),
      transition("open => closed", [animate("0.5s")]),
      transition("closed => open", [animate("0.5s")]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  private _destroyed$ = new Subject<void>();

  navbar$: Observable<Navbar> | undefined;
  routes = RouteUrls;
  isMobileMenuOpen = signal(false);
  strapiUrl: string;
  isSticky = false;
  cartIcon = faCartShopping;
  barsIcon = faBars;
  timesIcon = faTimes;
  faPowerOff = faPowerOff;
  user$: Observable<User | null>;

  constructor(
    private readonly navbarService: NavbarService,
    private readonly modalService: ModalService,
    private readonly basketItemsService: BasketItemsService,
    private readonly configService: AppConfigService,
    private readonly authService: AuthService,
  ) { }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit() {
    this.strapiUrl = this.configService.getConfigOrThrow().strapiUrl;

    this.navbar$ = this.navbarService.getNavbar().pipe(
      map((response) => {
        return response.data.navbar.data.attributes;
      })
    );

    this.user$ = this.authService.user$;
  }

  @HostListener("window:scroll", ["$event"]) onWindowScroll() {
    if (window.scrollY > 48) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  get count() {
    return this.basketItemsService.length;
  }

  get total() {
    return this.basketItemsService.total;
  }

  openBasket() {
    this.modalService.openModal<BasketPopupComponent>(
      BasketPopupComponent,
      BASKET_MODAL_REF
    );
  }

  handleMobileMenuStateChange(isOpen: boolean) {
    this.isMobileMenuOpen.set(isOpen);
  }

  login() {
    this.modalService.openModal<LoginPopupComponent>(
      LoginPopupComponent,
      LOGIN_MODAL_REF
    );
  }

  logout() {
    this.authService.logout().pipe(takeUntil(this._destroyed$))
      .subscribe(() => location.reload());
  }
}
