@if (configService.config$ | async) {
  <div class="h-full flex flex-col">
    @if (showNavbar) {
      <app-navbar class="fixed z-50 pointer-events-none"></app-navbar>
    }
    <app-notification></app-notification>
    <div class="h-full overflow-hidden">
      @if (isLoading$ | async) {
        <app-spinner></app-spinner>
      }

      <router-outlet></router-outlet>

      <app-footer></app-footer>
    </div>
  </div>
  <app-chat></app-chat>
} @else {
  <app-spinner></app-spinner>
}
