import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCommentDots, faXmark } from '@fortawesome/free-solid-svg-icons';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { ChatService, SendMessageRequest } from '../../services/chat.service';
import { LoaderService } from '../../services/loader.service';
import { NotificationService } from '../../services/notification.service';
import { TextInputComponent } from '../../shared/components/text-input/text-input.component';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [FontAwesomeModule, TextInputComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  readonly faCommentDots = faCommentDots;
  readonly faXMark = faXmark;
  strapiUrl: string;
  showChat: boolean = false;
  private destroy$ = new Subject<void>();

  formGroup!: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly loaderService: LoaderService,
    private readonly chatService: ChatService,
    private readonly notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group(
      {
        name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(256)]),
        message: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
        category: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      });
  }

  toggleChat() {
    this.showChat = !this.showChat;
    this.formGroup.reset();
  }

  sendMessage() {
    this.loaderService.show();

    const request: SendMessageRequest = {
      name: this.formGroup.get("name")?.value,
      email: this.formGroup.get("email")?.value,
      message: this.formGroup.get("message")?.value,
      category: this.formGroup.get("category")?.value,
    };

    this.chatService.sendMessage(request).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.loaderService.hide();
        this.notificationService.showSuccess("Your message has been sent.");
        this.showChat = false;
      }),
      catchError(() => {
        this.notificationService.showError("An error occured. Try again.");
        this.loaderService.hide();
        return of(null);
      })
    ).subscribe();
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
