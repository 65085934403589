import { Routes } from '@angular/router';
import { EmptyBasketGuard } from './containers/basket/services/basket-guard';
import { AuthGuard } from './services/auth/auth-guard';
import { RouteUrls } from './shared/enums/route-urls.enum';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: RouteUrls.Home,
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
      },
      {
        path: RouteUrls.VideoGenerator,
        loadComponent: () =>
          import(
            './containers/video-generator-form/video-generator-form.component'
          ).then((c) => c.VideoGeneratorFormComponent),
      },
      {
        path: RouteUrls.Account,
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
        canActivate: [AuthGuard],
      },
      {
        path: RouteUrls.Basket,
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
        canActivate: [EmptyBasketGuard],
      },
      {
        path: RouteUrls.Order,
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
        canActivate: [EmptyBasketGuard],
      },
      {
       path: RouteUrls.OrderWithId,
       loadComponent: () =>
         import('./core/page/page.component').then((c) => c.PageComponent),
      },
      {
        path: RouteUrls.AboutUs,
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
      },
      {
        path: RouteUrls.Feedback,
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
      },
      {
        path: RouteUrls.PageNotFound,
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
      },
      {
        path: '**',
        pathMatch: 'full',
        loadComponent: () =>
          import('./core/page/page.component').then((c) => c.PageComponent),
      },
    ],
  },
];
