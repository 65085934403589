import { gql } from 'apollo-angular';

export const FooterQuery = gql`
query Footer {
  footer {
    data {
      attributes {
        sectionsRows {
          title
          links {
            label
            url
          }
          imagesWithLinks {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            url
          }
          imagesWithFancyboxes {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            fancybox {
              data {
                attributes {
                  title
                  content
                }
              }
            }
          }
          newsletterSignup {
            placeholder
            label
            dataPolicyLabel
            dataPolicyTooltip
          }
        }
        sectionsColumns {
          title
          links {
            label
            url
          }
          imagesWithLinks {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            url
          }
          imagesWithFancyboxes {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            fancybox {
              data {
                attributes {
                  title
                  content
                }
              }
            }
          }
          newsletterSignup {
            placeholder
            label
            dataPolicyLabel
            dataPolicyTooltip
          }
        }
        socialSites {
          image {
            data {
              attributes {
                url
              }
            }
          }
          text
        }
        properFooter
      }
    }
  }
}
`;
