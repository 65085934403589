@if (footer$ | async; as footer) {
  <div class="footer-wrapper">
    <div class="sections-wrapper">
      @for (section of footer.sectionsColumns; track $index) {
        <app-footer-section [footerSection]="section"/>
      }
      <div class="section-rows">
        @for (section of footer.sectionsRows; track $index) {
          <app-footer-section  [footerSection]="section"/>
        }
      </div>
    </div>
    <div class="flex flex-col">
      <markdown>{{ footer.socialSites.text }}</markdown>
    </div>
    <markdown class="flex text-center proper-footer">{{ footer.properFooter }}</markdown>
  </div>
}
