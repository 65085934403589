import { Component, Input } from '@angular/core';
import { AppConfigService } from '../../../config.service';
import { Fancybox } from '../../../models/collectionTypes/fancybox.model';
import { FooterSection } from '../../../models/components/footer/footerSection.model';
import { ModalService } from '../../../services/modal.service';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';
import {
  FANCYBOX_REF,
  FancyboxComponent,
} from '../../modals/fancybox/fancybox.component';

@Component({
  standalone: true,
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.css'],
  imports: [ImageUrlDirective]
})
export class FooterSectionComponent {
  @Input() footerSection: FooterSection;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService,
    private readonly modalService: ModalService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  openModal(fancybox: Fancybox) {
    const ref = this.modalService.openModal<FancyboxComponent>(
      FancyboxComponent,
      FANCYBOX_REF,
    );

    ref.setInput('fancybox', fancybox);
  }

  signupToNewsletter() { }
}
