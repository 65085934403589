import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideMarkdown } from 'ngx-markdown';
import { routes } from './app.routes';
import { EmptyBasketGuard } from './containers/basket/services/basket-guard';
import { graphqlProvider } from './graphql.provider';
import { AuthGuard } from './services/auth/auth-guard';
import { WithCredentialsInterceptor } from './services/auth/with-credentials-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideMarkdown(),
    provideHttpClient(),
    graphqlProvider,
    provideAnimationsAsync(),
    AuthGuard,
    EmptyBasketGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
  ],
};
