import { OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Inject, InjectionToken } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { LoaderService } from '../../../services/loader.service';
import { ModalService } from '../../../services/modal.service';
import { REGISTER_MODAL_REF, RegisterPopupComponent } from '../register-popup/register-popup.component';
import { Subject, catchError, of, switchMap, takeUntil, tap } from 'rxjs';
import { TextInputComponent } from "../../../shared/components/text-input/text-input.component";
import { FORGOTTEN_PASSWORD_MODAL_REF, ForgottenPasswordPopupComponent } from '../forgotten-password-popup/forgotten-password-popup.component';

export const LOGIN_MODAL_REF = new InjectionToken<OverlayRef>(
  "LOGIN_MODAL_REF"
);

@Component({
  selector: 'app-login-popup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TextInputComponent],
  templateUrl: './login-popup.component.html',
  styleUrl: './login-popup.component.scss'
})
export class LoginPopupComponent {
  private _destroyed$ = new Subject<void>();

  loginForm!: FormGroup;
  authFailed: boolean = false;
  signedIn: boolean = false;

  constructor(@Inject(LOGIN_MODAL_REF) public overlayRef: OverlayRef,
    private readonly authService: AuthService,
    private readonly loaderService: LoaderService,
    private readonly modalService: ModalService,
    private readonly formBuilder: FormBuilder) { }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.authFailed = false;
    this.loginForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
        password: ['', [Validators.required, Validators.minLength(8)]]
      });
  }

  login() {
    if (!this.loginForm.valid) {
      return;
    }

    this.loaderService.show();

    const email = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;

    this.authService.login(email, password).pipe(
      takeUntil(this._destroyed$),
      switchMap(() => this.authService.tryGetUser()),
      tap(() => {
        this.loaderService.hide();
        this.closePopup();
      }),
      catchError(() => {
        this.authFailed = true;
        this.loaderService.hide();
        return of(null);
      })
    ).subscribe();
  }

  closePopup() {
    this.overlayRef.dispose();
  }

  redirectToRegister() {
    this.closePopup();
    this.modalService.openModal<RegisterPopupComponent>(
      RegisterPopupComponent,
      REGISTER_MODAL_REF
    );
  }

  redirectToForgottenPassword() {
    this.closePopup();
    this.modalService.openModal<ForgottenPasswordPopupComponent>(
      ForgottenPasswordPopupComponent,
      FORGOTTEN_PASSWORD_MODAL_REF
    );
  }
}