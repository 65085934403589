import { gql } from 'apollo-angular';
import { ButtonLinkData } from './componentsData/buttonLink.componentData';

export const NavbarQuery = gql`
  query Navbar {
    navbar {
      data {
        attributes {
          logo {
            data {
              attributes {
                url
              }
            }
          }
          linksLeft {
            ${ButtonLinkData}
          }
          linksRight {
            ${ButtonLinkData}
          }
        }
      }
    }
  }
`;
