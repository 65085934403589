import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FooterQuery } from '../../queries/footer.query';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private apollo: Apollo) {}

  getFooter(): Observable<ApolloQueryResult<any>> {
    const queryObj = {
      query: FooterQuery,
    };

    return this.apollo.watchQuery(queryObj).valueChanges;
  }
}
