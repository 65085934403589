import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

export interface SendMessageRequest {
    email: string;
    name: string;
    category: string;
    message: string;
}

@Injectable({
    providedIn: 'root',
})

export class ChatService {
    private readonly apiUrl = `${environment.apiUrl}/Chat`;

    constructor(private http: HttpClient) { }

    sendMessage(request: SendMessageRequest): Observable<any> {
        return this.http
            .post(`${this.apiUrl}`, request);
    }
}