<div class="absolute inset-0 z-50">
  <div
    class="fixed backdrop-brightness-50 h-full w-full flex place-items-center place-content-center text-lg"
    (click)="closePopup()"
  >
    <div
      class="flex flex-col bg-white w-96 rounded-lg"
      (click)="$event.stopPropagation()"
    >
      <div
        class="bg-red-600 text-white text-3xl font-rockwell py-4 px-12 text-center rounded-t-lg"
      >
        Create an account
      </div>
      <div class="p-4">
        <form [formGroup]="formGroup" (submit)="register()">
          <app-text-input
            [control]="formGroup.get('firstName')!"
            placeholder="Name"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.get('email')!"
            placeholder="Email"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.get('password')!"
            placeholder="Password"
            type="password"
          ></app-text-input>
          <app-text-input
            [control]="formGroup.get('confirmPassword')!"
            placeholder="Repeat password"
            type="password"
          ></app-text-input>
          @if (
            formGroup.get("password")?.touched &&
            formGroup.get("confirmPassword")?.touched &&
            formGroup.errors?.["PasswordNoMatch"]
          ) {
            <span class="text-red-500 text-sm">Passwords don't match</span>
          }
          <div>
            <app-checkbox
              (onChanged)="handleTermsCheck($event)"
              [isChecked]="termsAccepted"
            >
              <span class="text-gray-500 text-lg">I accept </span>
              <span class="text-red-600 text-lg">the Terms and Conditions</span>
            </app-checkbox>

            <!-- Currenlty disabled -->
            <!-- <app-checkbox (onChanged)="handleNewsletterCheck($event)" [isChecked]="newsletterAccepted">
              <span class="text-gray-500 text-lg">Newsletter Agreement</span>
            </app-checkbox> -->
          </div>
          @if (authFailed) {
            <div class="text-center text-red-600">Something went wrong.</div>
          }
          <div class="mt-6 w-4/5 mx-auto">
            <button
              class="text-white font-bold py-3 text-xl rounded w-full"
              [ngClass]="
                formGroup.valid ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-400'
              "
              [disabled]="!formGroup.valid"
              type="submit"
            >
              Set up
            </button>
          </div>
        </form>
        <div class="mt-8 flex flex-col items-center">
          <button (click)="redirectToLogin()" class="hover:text-red-500">
            Already have an account? Log in.
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
