import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, map, switchMap } from 'rxjs';
import { AuthService } from './auth.service';
import { LOGIN_MODAL_REF, LoginPopupComponent } from '../../core/authentication/login-popup/login-popup.component';
import { ModalService } from '../modal.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly modalService: ModalService,
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isSignedIn();
  }

  isSignedIn(): Observable<boolean> {
    return this.authService.tryGetUser().pipe(
        switchMap(() => this.authService.user$),
        map(user => {
          if (user === null) {
            this.router.navigate(['']).then(() => 
              this.modalService.openModal<LoginPopupComponent>(
                LoginPopupComponent,
                LOGIN_MODAL_REF)
              );
            return false;
          }
          return true;
        })
      );
  }
}
