import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { createPersistedQueryLink } from 'apollo-angular/persisted-queries';
import { sha256 } from 'crypto-hash';
import { firstValueFrom } from 'rxjs';
import { AppConfigService } from './config.service';

export function apolloOptionsFactory(configService: AppConfigService): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  const appConfig = configService.getConfigOrThrow();

  //performance can be even improved by using GET requests and then caching its response for queries but then we need to handle the query length limit
  return {
    link: createPersistedQueryLink({ sha256 }).concat(httpLink.create({ uri: appConfig.graphApiUrl })),
    cache: new InMemoryCache(),
  };
}

export function createApollo(apolloOptionsService: AppConfigService) {
  return () => firstValueFrom(apolloOptionsService.getConfig());
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
    deps: [AppConfigService],
  },
];
