import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { NavbarQuery } from '../../queries/navbar.query';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  constructor(private apollo: Apollo) { }

  getNavbar(): Observable<ApolloQueryResult<any>> {
    const queryObj = {
      query: NavbarQuery,
    };

    return this.apollo.watchQuery(queryObj).valueChanges;
  }
}
