import { OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Inject, InjectionToken } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, takeUntil, switchMap, tap, catchError, of } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { LoaderService } from '../../../services/loader.service';
import { ModalService } from '../../../services/modal.service';
import { TextInputComponent } from '../../../shared/components/text-input/text-input.component';
import { LOGIN_MODAL_REF, LoginPopupComponent } from '../login-popup/login-popup.component';

export const FORGOTTEN_PASSWORD_MODAL_REF = new InjectionToken<OverlayRef>(
  "FORGOTTEN_PASSWORD"
);

@Component({
  selector: 'app-forgotten-password-popup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TextInputComponent],
  templateUrl: './forgotten-password-popup.component.html',
  styleUrl: './forgotten-password-popup.component.scss'
})
export class ForgottenPasswordPopupComponent {
  private _destroyed$ = new Subject<void>();

  formGroup!: FormGroup;

  constructor(@Inject(FORGOTTEN_PASSWORD_MODAL_REF) public overlayRef: OverlayRef,
    private readonly authService: AuthService,
    private readonly loaderService: LoaderService,
    private readonly modalService: ModalService,
    private readonly formBuilder: FormBuilder) { }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
      });
  }

  sendNewPassword() {
    if (!this.formGroup.valid) {
      return;
    }

    this.loaderService.show();

    const email = this.formGroup.get('email')?.value;

    this.authService.sendNewPassword(email).pipe(
      takeUntil(this._destroyed$),
      switchMap(() => this.authService.tryGetUser()),
      tap(() => {
        this.loaderService.hide();
        this.closePopup();
      }),
      catchError(() => {
        this.loaderService.hide();
        return of(null);
      })
    ).subscribe();
  }

  closePopup() {
    this.overlayRef.dispose();
  }

  redirectToLogin() {
    this.closePopup();
    this.modalService.openModal<LoginPopupComponent>(
      LoginPopupComponent,
      LOGIN_MODAL_REF
    );
  }
}
