import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { map, Observable } from 'rxjs';
import { AppConfigService } from '../../config.service';
import { FooterSectionComponent } from '../../containers/elements/footer-section/footer-section.component';
import { Footer } from '../../models/singleTypes/footer.model';
import { FooterService } from '../../services/strapi/footer.service';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  imports: [CommonModule, MarkdownModule, FooterSectionComponent],
})
export class FooterComponent implements OnInit {
  footer$: Observable<Footer> | undefined;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService,
    private readonly footerService: FooterService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }


  ngOnInit() {
    this.footer$ = this.footerService.getFooter().pipe(
      map((response) => {
        return response.data.footer.data.attributes;
      }),
    );
  }
}
