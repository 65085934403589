@if (navbar$ | async; as navbar) {
  <nav
    class="navbar flex-row justify-between w-[100vw] duration-100 hidden sm:flex"
    [ngClass]="
      isSticky
        ? 'bg-white text-dark-text shadow-md'
        : 'bg-transparent text-white p-4'
    "
    (onscroll)="onWindowScroll()"
  >
    <div
      class="flex flex-row gap-4 duration-100 pointer-events-auto"
      [ngClass]="isSticky ? 'items-center' : 'items-start'"
    >
      <a
        [routerLink]="routes.Home"
        class="flex text-inherit navbar-button bg-opacity-0 bg-white hover:bg-opacity-15"
      >
        Home
      </a>
      <div class="flex flex-row flex-wrap items-start gap-0">
        @for (buttonLink of navbar.linksLeft; track $index) {
          <app-button [buttonLink]="buttonLink"></app-button>
        }
      </div>
    </div>
    <div
      class="flex flex-row gap-4 pointer-events-auto"
      [ngClass]="isSticky ? 'items-center' : 'items-start'"
    >
      @if (user$ | async; as user) {
        <a
          [routerLink]="routes.Account"
          class="flex text-inherit navbar-button bg-opacity-0 bg-white hover:bg-opacity-15"
        >
          Account ({{ user!.firstName }})
        </a>
        <div
          class="flex flex-row items-center gap-2 text-inherit navbar-button bg-opacity-0 bg-white hover:bg-opacity-15 cursor-pointer"
          (click)="logout()"
        >
          Log out
          <fa-icon [icon]="faPowerOff" class="text-md"></fa-icon>
        </div>
      } @else {
        <div
          class="flex flex-row items-center gap-2 text-inherit navbar-button bg-opacity-0 bg-white hover:bg-opacity-15 cursor-pointer"
          (click)="login()"
        >
          Account (Log in)
        </div>
      }
      <div
        class="flex flex-row items-center gap-2 text-inherit navbar-button bg-opacity-0 bg-white hover:bg-opacity-15 cursor-pointer"
        (click)="openBasket()"
      >
        @if (this.count) {
          <div>${{ this.total }}</div>
          <div class="flex relative">
            <fa-icon [icon]="cartIcon" class="text-md"></fa-icon>
            <div
              class="absolute -top-2 -right-2 bg-red-600 text-white rounded-full p-1 text-xs"
            >
              {{ this.count }}
            </div>
          </div>
        } @else {
          <div>USD</div>
          <fa-icon [icon]="cartIcon" class="text-md"></fa-icon>
        }
      </div>
    </div>
  </nav>

  <nav
    class="flex-row justify-between w-[100vw] duration-100 pt-9 px-10 flex sm:hidden pointer-events-auto"
    [ngClass]="
      isSticky && !isMobileMenuOpen()
        ? 'bg-white text-dark-text shadow-md'
        : 'bg-transparent text-white'
    "
    (onscroll)="onWindowScroll()"
  >
    <div
      class="flex flex-col cursor-pointer z-[100]"
      (click)="handleMobileMenuStateChange(!isMobileMenuOpen())"
    >
      <div class="mobile-navbar-button">
        <fa-icon
          [icon]="isMobileMenuOpen() ? timesIcon : barsIcon"
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <span>Menu</span>
    </div>

    <div class="flex flex-col cursor-pointer z-[100] relative" (click)="openBasket()">
      <div class="mobile-navbar-button">
        <fa-icon
          [icon]="cartIcon"
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <span>Cart</span>
      <div
        class="absolute -top-2 -right-2 bg-red-600 text-white rounded-full p-1 text-xs"
      >
        {{ this.count }}
      </div>
    </div>

    <div
      class="mobile-menu"
      [@openCloseMobileMenu]="isMobileMenuOpen() ? 'open' : 'closed'"
    >
      <div class="fixed top-0 left-0 pt-40 flex flex-col w-full cursor-pointer">
        <ng-container
          [ngTemplateOutlet]="mobileNavBarItem"
          [ngTemplateOutletContext]="{
            name: 'Home',
            link: routes.Home,
          }"
        />

        @for (buttonLink of navbar.linksLeft; track $index) {
          <app-button
            class="mobile-navbar-item"
            [ngClass]="{ 'mobile-navbar-item-open': isMobileMenuOpen() }"
            [buttonLink]="buttonLink"
            (click)="handleMobileMenuStateChange(false)"
          ></app-button>
        }

        @if (user$ | async; as user) {
          <ng-container
            [ngTemplateOutlet]="mobileNavBarItem"
            [ngTemplateOutletContext]="{
              name: 'Account (' + user!.firstName + ')',
              link: routes.Account,
            }"
          />

          <ng-container
            [ngTemplateOutlet]="mobileNavBarItem"
            [ngTemplateOutletContext]="{
              name: 'Log out',
              fn: logout.bind(this),
            }"
          />
        } @else {
          <ng-container
            [ngTemplateOutlet]="mobileNavBarItem"
            [ngTemplateOutletContext]="{
              name: 'Account (Log in)',
              fn: login.bind(this),
            }"
          />
        }
      </div>
    </div>
  </nav>
}

<!-- temporary solution. ultimately will be replaced by app-button -->
<ng-template #mobileNavBarItem let-name="name" let-link="link" let-fn="fn">
  <a
    [routerLink]="link"
    (click)="fn()"
    (click)="handleMobileMenuStateChange(false)"
    class="mobile-navbar-item px-8 py-4 font-medium text-base hover:bg-white hover:bg-opacity-10 w-full"
    [ngClass]="{ 'mobile-navbar-item-open': isMobileMenuOpen() }"
  >
    {{ name }}
  </a>
</ng-template>
