import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';
import { AppConfig, AppConfigService } from './config.service';
import { ChatComponent } from "./core/chat/chat.component";
import { FooterComponent } from "./core/footer/footer.component";
import { NavbarComponent } from "./core/navbar/navbar.component";
import { NotificationComponent } from "./core/notification/notification.component";
import { PageComponent } from "./core/page/page.component";
import { SpinnerComponent } from "./core/spinner/spinner.component";
import { AuthService } from './services/auth/auth.service';
import { LoaderService } from './services/loader.service';
import { PriceListService } from './services/price-lists.service';
import { ImageUrlDirective } from './shared/directives/image-url.directive';
import { RouteUrlsWithHiddenNavbar } from './shared/enums/routeUrlsWithHiddenNavbar';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, PageComponent, NavbarComponent, FooterComponent, SpinnerComponent, CommonModule, NotificationComponent, ChatComponent, ImageUrlDirective],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'ElfieShop';
  isLoading$ = new BehaviorSubject<boolean>(false);
  configLoaded$?: Observable<AppConfig>;
  private destroy$ = new Subject<void>();
  showNavbar: boolean = true;

  constructor(private readonly loaderService: LoaderService,
    private readonly changeDetector: ChangeDetectorRef,
    public readonly configService: AppConfigService,
    public readonly priceListService: PriceListService,
    private readonly authService: AuthService,
    private readonly router: Router) {
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (Object.values(RouteUrlsWithHiddenNavbar).includes(event.url as RouteUrlsWithHiddenNavbar)) {
          this.showNavbar = false;
        }
        else {
          this.showNavbar = true;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.loaderService.loading$
      .pipe(
        takeUntil(this.destroy$),
        tap((loadingState) => {
          this.isLoading$.next(loadingState);
          this.changeDetector.detectChanges();
        })
      )
      .subscribe();

    this.authService.tryGetUser().pipe(
      takeUntil(this.destroy$)
    ).subscribe();
  }
}
