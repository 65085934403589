import { OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Inject, InjectionToken } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject, catchError, of, switchMap, takeUntil, tap } from 'rxjs';
import { AuthService, RegisterRequest } from '../../../services/auth/auth.service';
import { LoaderService } from '../../../services/loader.service';
import { ModalService } from '../../../services/modal.service';
import { TextInputComponent } from '../../../shared/components/text-input/text-input.component';
import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { LOGIN_MODAL_REF, LoginPopupComponent } from '../login-popup/login-popup.component';

export const REGISTER_MODAL_REF = new InjectionToken<OverlayRef>(
  "REGISTER"
);

@Component({
  selector: 'app-register-popup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CheckboxComponent, TextInputComponent],
  templateUrl: './register-popup.component.html',
  styleUrl: './register-popup.component.scss'
})
export class RegisterPopupComponent {
  private _destroyed$ = new Subject<void>();

  formGroup!: FormGroup;
  authFailed: boolean = false;
  signedIn: boolean = false;
  termsAccepted: boolean = false;
  newsletterAccepted: boolean = false;

  constructor(@Inject(REGISTER_MODAL_REF) public overlayRef: OverlayRef,
    private readonly authService: AuthService,
    private readonly loaderService: LoaderService,
    private readonly modalService: ModalService,
    private readonly formBuilder: FormBuilder) { }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.authFailed = false;
    this.formGroup = this.formBuilder.group(
      {
        firstName: ['', [Validators.required, Validators.maxLength(100)]],
        email: ['', [Validators.required, Validators.email, Validators.maxLength(256)]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
        termsAccepted: [false, [Validators.requiredTrue]],
        newsletterAccepted: [false,],
      }, { validators: this.confirmPasswordValidator });
  }

  register() {
    if (!this.formGroup.valid) {
      return;
    }

    this.loaderService.show();

    const firstName = this.formGroup.get('firstName')?.value;
    const email = this.formGroup.get('email')?.value;
    const password = this.formGroup.get('password')?.value;
    const newsletterAccepted = this.formGroup.get('newsletterAccepted')?.value;

    const request: RegisterRequest = {
      firstName: firstName,
      email: email,
      password: password,
      newsletterAccepted: newsletterAccepted
    };

    this.authService.register(request).pipe(
      takeUntil(this._destroyed$),
      switchMap(() => this.authService.login(request.email, request.password)),
      switchMap(() => this.authService.tryGetUser()),
      tap(() => {
        this.loaderService.hide();
        this.closePopup();
      }),
      catchError(() => {
        this.authFailed = true;
        this.loaderService.hide();
        return of(null);
      })
    ).subscribe();
  }

  closePopup() {
    this.overlayRef.dispose();
  }

  redirectToLogin() {
    this.closePopup();
    this.modalService.openModal<LoginPopupComponent>(
      LoginPopupComponent,
      LOGIN_MODAL_REF
    );
  }

  handleTermsCheck(termsAccepted: any): void {
    this.termsAccepted = termsAccepted;
    this.formGroup.get('termsAccepted')?.setValue(termsAccepted);
  }

  handleNewsletterCheck(newsletterAccepted: any): void {
    this.newsletterAccepted = newsletterAccepted;
    this.formGroup.get('newsletterAccepted')?.setValue(newsletterAccepted);
  }

  confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    return control.value.password === control.value.confirmPassword
      ? null
      : { PasswordNoMatch: true };
  };
}